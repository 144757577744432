import type { AppProps } from 'next/app';

import { ChakraProvider } from '@chakra-ui/react';
import theme from 'styles/theme';
import Fonts from 'styles/fonts';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthProvider } from 'context/auth';
import { BasUiProvider } from 'context/basUi';
import { NasUiProvider } from 'context/nasUi';

import 'styles/leaflet.min.css';
import { useEffect } from 'react';

const queryClient = new QueryClient();

const REFRESH_HOUR = 3;

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (new Date().getHours() !== REFRESH_HOUR) {
        window.localStorage.setItem('refresh', 'true');
        return;
      }

      const needToRefresh = JSON.parse(window.localStorage.getItem('refresh') ?? 'true');

      if (needToRefresh) {
        window.localStorage.setItem('refresh', 'false');
        window.location.replace(window.location.pathname);
        return;
      }
    }, 1000 * 60 * 50);

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BasUiProvider>
          <NasUiProvider>
            <ChakraProvider resetCSS theme={theme}>
              <Fonts />
              <Component {...pageProps} />
            </ChakraProvider>
          </NasUiProvider>
        </BasUiProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default MyApp;
