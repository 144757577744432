import { theme } from '@chakra-ui/react';

const link = {
  baseStyle: {
    ...theme.components.Link.baseStyle,
    _hover: { textDecoration: 'none' },
    _focus: { boxShadow: 'none' },
  },
};

export default link;
