import { Global } from '@emotion/react';

// 프로젝트에 사용되는 웹 폰트 설정
const Fonts = () => {
  return (
    <Global
      styles={` 

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 100;
        src: url('/fonts/heebo-v20-latin-100.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-100.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-100.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 200;
        src: url('/fonts/heebo-v20-latin-200.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-200.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-200.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/heebo-v20-latin-300.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-300.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-300.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/heebo-v20-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-regular.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/heebo-v20-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-500.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-500.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/heebo-v20-latin-600.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-600.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-600.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/heebo-v20-latin-700.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-700.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-700.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 800;
        src: url('/fonts/heebo-v20-latin-800.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-800.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-800.svg#Heebo') format('svg'); /* Legacy iOS */
      }

      @font-face {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 900;
        src: url('/fonts/heebo-v20-latin-900.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('/fonts/heebo-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/fonts/heebo-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('/fonts/heebo-v20-latin-900.woff') format('woff'), /* Modern Browsers */
          url('/fonts/heebo-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/fonts/heebo-v20-latin-900.svg#Heebo') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Thin.woff") format("woff");
        font-weight: 100;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-ThinItalic.woff") format("woff");
        font-weight: 100;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-LightItalic.woff") format("woff");
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Italic.woff") format("woff");
        font-weight: 400;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-MediumItalic.woff") format("woff");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-BoldItalic.woff") format("woff");
        font-weight: 700;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-Black.woff") format("woff");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: "Roboto";
        src: url("/fonts/Roboto-BlackItalic.woff") format("woff");
        font-weight: 900;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 400;
        src: url('../fonts/orbitron-v17-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-regular.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 500;
        src: url('../fonts/orbitron-v17-latin-500.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-500.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-500.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 600;
        src: url('../fonts/orbitron-v17-latin-600.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-600.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-600.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 700;
        src: url('../fonts/orbitron-v17-latin-700.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-700.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-700.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 800;
        src: url('../fonts/orbitron-v17-latin-800.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-800.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-800.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      @font-face {
        font-family: 'Orbitron';
        font-style: normal;
        font-weight: 900;
        src: url('../fonts/orbitron-v17-latin-900.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('../fonts/orbitron-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/orbitron-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/orbitron-v17-latin-900.woff') format('woff'), /* Modern Browsers */
            url('../fonts/orbitron-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/orbitron-v17-latin-900.svg#Orbitron') format('svg'); /* Legacy iOS */
      }
      `}
    />
  );
};

export default Fonts;

//사용하지 않는 폰트 임시제거
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap
//   src: url('../fonts/Pretendard-Thin.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-Thin.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap
//   src: url('../fonts/Pretendard-ExtraLight.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-ExtraLight.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap
//   src: url('../fonts/Pretendard-Light.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-Light.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap
//   src: url('../fonts/Pretendard-Regular.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-Regular.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap
//   src: url('../fonts/Pretendard-Medium.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-Medium.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap
//   src: url('../fonts/Pretendard-SemiBold.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-SemiBold.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap
//   src: url('../fonts/Pretendard-Bold.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-Bold.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap
//   src: url('../fonts/Pretendard-ExtraBold.woff') format("woff");
//   src: local(''),
//       url('../fonts/Pretendard-ExtraBold.woff') format("woff")
// }
// @font-face {
//   font-family: 'Pretendard';
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap
//   src: url('../fonts/Pretendard-Black.woff') format("woff");
//   src: local(''),
//     url('../fonts/Pretendard-Black.woff') format("woff")
// }
