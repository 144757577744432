const colors = {
  ship: {
    white: '#F7F7F7',
    green: '#18E85F',
    yellow: '#FAFF00',
    red: '#EF2424',
  },
  collider: {
    green: 'rgba(2, 214, 100, 0.2)',
    yellow: 'rgba(255, 230, 38, 0.2)',
    red: 'rgba(252, 0, 0, 0.2)',
  },
  primary: '#342e87',
  gray: {
    50: '#F7F8F0',
    100: '#E9EBED',
    200: '#C5C8CF',
    300: '#646F7A',
    400: '#374558',
    500: '#3E3D43',
    600: '#22232D',
  },
  danger: '#E82116',
  warning: '#FF3222',
  info: '#3AA803',
  black: '#171721',
  transparent: '#00000000',

  primary_variant: {
    primary_variant: {
      50: '#e8e9f5',
      100: '#c6c8e5',
      200: '#a1a4d4',
      300: '#7d81c2',
      400: '#6265b5',
      500: '#4a4aa9',
      600: '#44429f',
      700: '#3b3893',
      800: '#342e87',
      900: '#271c72',
    },
    triadic: {
      purple: {
        50: '#f2e5ef',
        100: '#dfbdda',
        200: '#ca93c2',
        300: '#b469a9',
        400: '#a34c97',
        500: '#933387',
        600: '#872e81',
        700: '#772879',
        800: '#682370',
        900: '#4e1b5e',
      },
      red: {
        50: '#f7eaed',
        100: '#edccd0',
        200: '#d2999a',
        300: '#bf7476',
        400: '#c55a58',
        500: '#c64e45',
        600: '#b84642',
        700: '#a63e3b',
        800: '#993936',
        900: '#87342e',
      },
    },
    analogous: {
      blue: {
        50: '#e3f4fc',
        100: '#b8e3f7',
        200: '#8cd1f2',
        300: '#68bfeb',
        400: '#54b2e6',
        500: '#4aa5e1',
        600: '#4597d3',
        700: '#3e85bf',
        800: '#3974aa',
        900: '#2e5587',
      },
      purple: {
        50: '#f1e6f3',
        100: '#dcc1e3',
        200: '#c698d1',
        300: '#af71bd',
        400: '#9e55af',
        500: '#8e3ea1',
        600: '#81399a',
        700: '#703391',
        800: '#602e87',
        900: '#452574',
      },
    },
    split_complements: {
      brown: {
        50: '#ffebca',
        100: '#ebcdab',
        200: '#d1ae89',
        300: '#b39066',
        400: '#9d774a',
        500: '#87602e',
        600: '#7c5628',
        700: '#6d491f',
        800: '#603b18',
        900: '#512d0e',
      },
      green: {
        50: '#f1f7e9',
        100: '#dbebc7',
        200: '#c4dea4',
        300: '#add280',
        400: '#9bc864',
        500: '#8abf49',
        600: '#7baf41',
        700: '#679b37',
        800: '#55872e',
        900: '#33651d',
      },
    },
    complementary: {
      50: '#f7feeb',
      100: '#ebfbcc',
      200: '#dff9ab',
      300: '#d3f68a',
      400: '#caf371',
      500: '#c2ef59',
      600: '#b7dd52',
      700: '#a8c648',
      800: '#99ae3e',
      900: '#81872e',
    },
    monochromatic: {
      1: '#CCCBE0',
      2: '#9A97C3',
      3: '#737192',
      4: '#272265',
      5: '#1A1743',
      6: '#131132',
    },
  },
};

export default colors;
