/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useReducer } from 'react';

interface BasUiState {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const basUiContext = createContext<BasUiState | null>(null);

export const useBasUi = () => {
  const state = useContext(basUiContext);
  if (!state) throw new Error('Cannot find basUiContext Provider');
  return state;
};

export const BasUiProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useProvideBasUi();
  return <basUiContext.Provider value={value}>{children}</basUiContext.Provider>;
};

type State = {
  expectedPath: {
    A: boolean;
    B: boolean;
    C: boolean;
    [key: string]: boolean;
  };
  expectedPathOpacity: number;
  equidistanceLevel: number;
};

type Action = {
  type: 'UPDATE_EXPECTED_PATH' | 'UPDATE_EQUIDISTANCE_LEVEL' | 'UPDATE_EXPECTED_PATH_OPACITY';
  [key: string]: any;
};

const initialState: State = {
  expectedPath: {
    A: true,
    B: true,
    C: true,
  },
  expectedPathOpacity: 9,
  equidistanceLevel: 4,
};

function useProvideBasUi() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'UPDATE_EXPECTED_PATH':
      return {
        ...state,
        expectedPath: {
          ...state.expectedPath,
          [action.id]: !state.expectedPath[action.id],
        },
      };
    case 'UPDATE_EXPECTED_PATH_OPACITY':
      return {
        ...state,
        expectedPathOpacity: action.opacity,
      };
    case 'UPDATE_EQUIDISTANCE_LEVEL':
      return { ...state, equidistanceLevel: action.level };
    default:
      throw new Error();
  }
}
