/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useReducer } from 'react';

interface NasUiState {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const nasUiContext = createContext<NasUiState | null>(null);

export const useNasUi = () => {
  const state = useContext(nasUiContext);
  if (!state) throw new Error('Cannot find nasUiContext Provider');
  return state;
};

export const NasUiProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useProvideNasUi();
  return <nasUiContext.Provider value={value}>{children}</nasUiContext.Provider>;
};

type State = {
  interfaceDayNight: 'DAY' | 'NIGHT';
  is_utc: boolean;
  is_relative: boolean;
  is_knot: boolean;
  is_head_up: boolean;
  info_type: {
    green: boolean;
    yellow: boolean;
    red: boolean;
  };
  enc_object: number;
};

type Action = {
  type: 'UPDATE_INTERFACE_DAY_NIGHT' | 'UPDATE_STATE' | 'INIT_STATE' | 'UPDATE_INFO_TYPE';
  [key: string]: any;
};

const initialState: State = {
  interfaceDayNight: 'DAY',
  is_utc: true,
  is_relative: true,
  is_knot: true,
  is_head_up: true,
  info_type: {
    green: true,
    yellow: true,
    red: true,
  },
  enc_object: 1,
};

function useProvideNasUi() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'UPDATE_INTERFACE_DAY_NIGHT':
      return { ...state, interfaceDayNight: action.interface };
    case 'UPDATE_STATE':
      return { ...state, [action.name]: action.value };
    case 'INIT_STATE':
      return { ...state, ...action.value };
    case 'UPDATE_INFO_TYPE':
      return {
        ...state,
        info_type: {
          ...state.info_type,
          [action.name]: action.value,
        },
      };
    default:
      throw new Error();
  }
}
