import { extendTheme } from '@chakra-ui/react';
import foundations from './foundations';

import styles from './styles';
import components from './components';

const overrides = {
  ...foundations,
  styles,
  components,
};

export default extendTheme(overrides);
