import { theme } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  ...theme.styles,
  global: () => ({
    ...theme.styles.global,
    '#__next': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    html: {
      fontFamily: 'Roboto, sans-serif',
    },
    body: {
      overscrollBehavior: 'none',
      userSelect: 'none',
      fontFamily: 'Roboto, sans-serif',
    },
  }),
};

export default styles;
